import { render, staticRenderFns } from "./ModelEditor.vue?vue&type=template&id=461e8bf9&scoped=true&"
import script from "./ModelEditor.vue?vue&type=script&lang=ts&"
export * from "./ModelEditor.vue?vue&type=script&lang=ts&"
import style0 from "./ModelEditor.vue?vue&type=style&index=0&id=461e8bf9&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461e8bf9",
  null
  
)

export default component.exports