import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VSlideYReverseTransition,[(_vm.show&&_vm.models.length)?_c(VSheet,{staticClass:"model-list",attrs:{"width":"100%"}},[_c(VItemGroup,{staticClass:"flex-grow-1",attrs:{"mandatory":"","value":_vm.selectedIndex},on:{"change":_vm.select}},[_c('transition-group',{staticClass:"model-group d-flex pa-1 pa-xl-2",attrs:{"name":"move"}},_vm._l((_vm.models),function(model,i){return _c(VItem,{key:model.id,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c(VCard,{staticClass:"ma-1 ma-xl-2",attrs:{"color":model.error?'#631f1f':active?'grey darken-2':'grey darken-3'},on:{"click":toggle}},[_c(VTooltip,{attrs:{"top":"","disabled":!model.error},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VImg,_vm._g(_vm._b({attrs:{"src":model.thumbnail,"width":model.error?_vm.paneHeight:model.aspectRatio*_vm.paneHeight,"height":_vm.paneHeight},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[(!model.error)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}}):_c(VIcon,[_vm._v("mdi-alert-circle")])],1)]},proxy:true}],null,true)},'v-img',attrs,false),on),[_c(VCardTitle,{staticClass:"ml-1 pa-0 flex-nowrap text-subtitle-2 text-xl-subtitle-1"},[_c('span',{staticClass:"model-item-title text-truncate"},[_vm._v(_vm._s('#' + model.id + ' ' + model.name))]),_c(VSpacer),(active)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.remove(model.id)}}},[_c(VIcon,{attrs:{"size":"20"}},[_vm._v("mdi-close")])],1):_vm._e()],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(model.error)+" ")])],1)]}}],null,true)})}),1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }