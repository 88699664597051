
import { App } from '@/app/App';
import Vue from 'vue';

export default Vue.extend({
    name: "ModelInfo",
    props: {
        value: Boolean,
        id: Number,
    },
    data: () => ({
        url: '',
        settingsJSON: '',
    }),
    watch: {
        value(value: boolean) {
            if (value) {
                const model = App.getModel(this.id);
                const settings = model?.pixiModel?.internalModel.settings;

                this.url = model?.url || '';
                this.settingsJSON = JSON.stringify(settings?.json || {}, null, 2);
            }
        },
    },
    methods: {
        log() {
            console.log(JSON.parse(this.settingsJSON));
        },
    },
});
