import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"width":"1000","max-width":"90vw","value":_vm.value},on:{"input":function($event){return _vm.$emit('input',$event)}}},[_c(VCard,{staticClass:"d-flex flex-column",attrs:{"height":"90vh"}},[_c(VToolbar,{staticClass:"flex-grow-0",attrs:{"color":"primary","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Model Info")]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.log}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-console")])],1)]}}])},[_c('span',[_vm._v("Print to console")])]),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('input',false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"pa-8 overflow-auto"},[_c('div',{staticClass:"mb-6 text-no-wrap"},[_vm._v("URL: "+_vm._s(_vm.url))]),_c('pre',{staticClass:"code"},[_vm._v(_vm._s(_vm.settingsJSON))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }