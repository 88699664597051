
import Vue from 'vue';
import ModelPicker from './ModelPicker.vue';
import { validateURL } from '@/app/data';
import { App } from '@/app/App';

export default Vue.extend({
    components: { ModelPicker },

    name: "ModelCreation",
    props: {
        value: Boolean,
    },
    data: () => ({
        url: 'https://jsd.toshiki.dev/gh/andatoshiki/toshiki-live2d/Live2D/Senko_Normals/senko.model3.json',

        dropHelpDialog: false,

        picker: {
            dialog: false,
        },

        urlError: false,
        urlMessages: [] as string[],
    }),
    watch: {
        url(value) {
            const message = validateURL(value) || '';

            this.urlError = /error/i.test(message);
            this.urlMessages = [message].filter(Boolean);
        },
    },
    methods: {
        create() {
            this.url = this.url.replace(/\s/, '');

            if (!this.url) {
                this.urlError = true;
                this.urlMessages = ['Please enter a URL'];
            }

            if (this.urlError) {
                return;
            }

            const id = App.addModel(this.url);

            this.$emit('input', false);
            this.$emit('create', id);
        },
    },
});
