
import Vue from 'vue';
import { ModelEntity } from '@/app/ModelEntity';
import { App } from '@/app/App';

export default Vue.extend({
    name: "ModelList",
    props: {
        value: Number,
        show: Boolean,
    },
    data: () => ({
        models: [] as ModelEntity[],
    }),
    computed: {
        paneHeight() {
            return this.$vuetify.breakpoint.xl ? 192 : 144;
        },
        selectedIndex() {
            return this.models.findIndex(model => model.id === this.value);
        },
    },
    created() {
        this.models = App.models;
    },
    methods: {
        select(index: number) {
            const id = this.models[index]?.id ?? 0;

            this.$emit('input', id);
        },
        remove(id: number) {
            if (this.models.length === 1) {
                this.$emit('input', 0);
            }

            App.removeModel(id);
        },
    },
});
