import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"settings pa-3 flex-column flex-grow-1"},[_c('div',[_c(VSlider,{staticClass:"mt-4 mb-10",attrs:{"dense":"","prepend-icon":"mdi-volume-high","messages":~~(_vm.volume*100)+'%',"min":"0","max":"1","step":"0.02"},model:{value:(_vm.volume),callback:function ($$v) {_vm.volume=$$v},expression:"volume"}}),_c(VSwitch,{staticClass:"v-input--reverse",attrs:{"label":"Show hit area frames"},model:{value:(_vm.hitAreaFrames),callback:function ($$v) {_vm.hitAreaFrames=$$v},expression:"hitAreaFrames"}}),_c(VSwitch,{staticClass:"v-input--reverse",attrs:{"label":"Show model frames"},model:{value:(_vm.modelFrame),callback:function ($$v) {_vm.modelFrame=$$v},expression:"modelFrame"}}),_c(VSwitch,{staticClass:"v-input--reverse",attrs:{"label":"Show stats"},model:{value:(_vm.stats),callback:function ($$v) {_vm.stats=$$v},expression:"stats"}}),(_vm.currentBackground)?[_c(VDivider),_c(VSubheader,{staticClass:"px-0"},[_vm._v("Background")]),_c('div',{staticClass:"mt-2 d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.currentBackground))]),_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary"},on:{"click":_vm.resetBackground}},[_vm._v("Reset")])],1)]:_vm._e()],2),_c(VSpacer),_c(VDivider),_c('div',{staticClass:"pt-4 d-flex align-center"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-github")]),_vm._m(0)],1),_c('span',{staticClass:"pt-4 body-2 text--secondary"},[_vm._v("Last built: "+_vm._s(_vm.lastUpdated))])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_vm._v("View source or report bugs on "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://github.com/andatoshiki/toshiki-live2d-viewer"}},[_vm._v("GitHub")])])
}]

export { render, staticRenderFns }